import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Stories from "./Stories/Stories";
import Clouds from "./Stories/Clouds";
import Foreword from "./Foreword/Foreword";
import { Header } from "./Header/Header";
import "./App.css";

class App extends React.Component {
  
  render() {
    return (
      <div className="container">
        <Clouds />
        <Router>
        <Header />
        <div className="main">
          <Switch>
            <Route exact path="/">
              <Stories />
            </Route>
            <Route path="/foreword">
              <Foreword />
            </Route>
          </Switch>
        </div>
        </Router>
      </div>    
    );
  }
}

export default App;