import React from "react";

class Story extends React.Component {
  render() {

    return (
      <div className="storybox">
        <div
          className="fadeIn typo"
          key={Math.random()}
        >
          <p id="story">{this.props.story}</p>
        </div>
        <div 
          className="button typo-nav"
          onClick={this.props.handleClick}
        >
          <a id="newquote">
            Спросить у мессии
          </a>
        </div>
      </div>
    );
  }
}

export default Story;