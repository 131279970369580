import * as React from "react";
import { motion } from "framer-motion";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const variants = {
  open: {
    x: 0,
    display: 'inline-block',
    opacity: 1,
    transition: {
      x: { type: "spring", stiffness: 100, damping: 60, velocity: -800 }
    }
  },
  closed: {
    x: -100,
    display: 'none',
    opacity: 0,
    transition: {
      // x: { stiffness: 1000 }
      x: { type: "inertia", stiffness: 100, velocity: -100 }
    }
  }
};

const menus = [
  ["/","Справочник\u00A0мессии"], 
  ["/foreword","Предисловие"]  
];

export const MenuItem = ({ i }) => {
  
  const menu = menus[i];
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <Link to={menu[0]}>
        {menu[1]}
      </Link>     
    </motion.li>
  );
};
