import React from 'react';
import Toggle from 'react-toggled';

const Clouds = () => {
  return (
    <Toggle>
      {({on, getTogglerProps}) => (
        <React.Fragment>
          <a href="#" id="cloudsToggle" data-tooltip="Управляй облаками" data-tooltip-location="left" tabIndex="-1" {...getTogglerProps()}>
            <svg id="Normal" className="social-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 200">
              <path className="shareIt" d="M180.5,167.7h-123C25.8,167.7,0,142,0,110.2c0-22.6,12.9-42.6,32.8-52C34.3,25.9,61.2,0,93.9,0
              c19.3,0,37.1,9,48.6,24c4.3-1.4,8.8-2.2,13.5-2.2c23.4,0,42.4,19,42.4,42.4c0,1,0,1.9-0.1,2.9c20.1,7.3,34.2,26.7,34.2,48.8
              C232.4,144.5,209.1,167.7,180.5,167.7z M93.9,21c-22.2,0-40.2,18-40.2,40.2c0,1.1,0,2.2,0.1,3.3l0.7,8.4l-8,2.5
              C31.2,80.2,21,94.2,21,110.2c0,20.1,16.4,36.5,36.5,36.5h123c17.1,0,30.9-13.9,30.9-30.9c0-15.5-11.5-28.6-26.8-30.7l-12.8-1.7
              l4.3-12.2c0.8-2.3,1.2-4.7,1.2-7.1c0-11.8-9.6-21.4-21.4-21.4c-4.1,0-8.1,1.2-11.6,3.4l-9.6,6.2l-5.4-10.1
              C122.4,29.1,108.8,21,93.9,21z"
              />
            </svg>
          </a>
          <div id="cloudsWrapper" hidden={on ? true : false}>  
            <div className="clouds"></div>
          </div>
        </React.Fragment>
      )}
    </Toggle>
  );
}

export default Clouds;