import * as React from "react";
import { motion } from "framer-motion";
// import { useState } from 'react';

// export const MenuToggle = ({ toggle }) =>  {
  
//   const [isShown, setIsShown] = useState(false);

//   return (
//     <a href="#"
//       onClick={toggle}
//       onMouseEnter={() => setIsShown(true)}
//       onMouseLeave={() => setIsShown(false)}>
//       {isShown
//         ? <img src="./img/Logo__active.svg" />
//         : <img src="./img/Logo.svg" />
//       }
//     </a>
//   );
// }

const Path = props => (
  <motion.path
    fill="transparent"
    // strokeWidth="3"
    // stroke="hsl(0, 0%, 18%)"
    // strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle }) => (
  <a href="#" onClick={toggle}> 
    <svg xmlns="http://www.w3.org/2000/svg" width="110.144" height="53.88" viewBox="0 0 110.144 53.88">
      <g transform="translate(-196.37 -165.5)">
        <Path d="M298.524,192.477a72.241,72.241,0,0,0,7.946-12.454q-1.338-1.632-2.763-3.186-1.3,2.344-2.753,4.585a77.042,77.042,0,0,1-5.489,7.444,72.523,72.523,0,0,0-22.535-16.8,58.531,58.531,0,0,0-19.659-6.107,59.458,59.458,0,0,0-7.4-.46,58.8,58.8,0,0,0-47.611,24.2q-.982,1.342-1.887,2.741.926,1.431,1.933,2.8a59.033,59.033,0,0,0,57.85,23.237,58.472,58.472,0,0,0,14.4-4.511,72.541,72.541,0,0,0,24.88-17.85,76.948,76.948,0,0,1,5.648,7.68q1.4,2.165,2.648,4.428,1.435-1.567,2.783-3.214A72.251,72.251,0,0,0,298.524,192.477Zm-98.579.036a50.588,50.588,0,0,1,42.609-23.268c17.884,0,37.6,10.3,49.744,23.268-12.286,12.75-31.861,23.268-49.744,23.268A50.588,50.588,0,0,1,199.945,192.513Z" fill="#9ddcf2"
        />
        <Path d="M569,228.575a24.6,24.6,0,0,1-41.719,17.616,24.841,24.841,0,0,1-7.062-12.708,25.4,25.4,0,0,1-.556-4.908,24.859,24.859,0,0,1,15.76-22.847A24.558,24.558,0,0,1,569,228.575Zm-2.015,0a22.357,22.357,0,0,0-.432-4.319,21.583,21.583,0,0,0-36.774-10.792,22.229,22.229,0,0,0,0,30.225,21.634,21.634,0,0,0,36.78-10.791,22.355,22.355,0,0,0,.43-4.323Z" transform="translate(-299.983 -35.738)" fill="#9ddcf2"
          variants={{
            closed: { opacity: 0 },
            open: { opacity: 1 }
          }}
          
          transition={{ duration: 0.3 }}
        />
        <Path d="M699.6,361.244a13.379,13.379,0,1,1-13.379-13.4A13.391,13.391,0,0,1,699.6,361.244Zm-18.057-9.265a4.507,4.507,0,1,0,4.5,4.507,4.5,4.5,0,0,0-4.5-4.507Z" transform="matrix(-0.259, 0.966, -0.966, -0.259, 771.625, -376.314)" fill="#fff"
          variants={{
            closed: { opacity: 0 },
            open: { opacity: 1 }
          }}
          transition={{ duration: 0.1 }}
        />
        <Path d="M701.217,362.055a14.189,14.189,0,1,1-14.189-14.215A14.2,14.2,0,0,1,701.217,362.055Zm-19.15-9.826a4.78,4.78,0,1,0,4.771,4.78,4.776,4.776,0,0,0-4.771-4.78Z" transform="translate(-442.135 -169.218)" fill="#37859e"
          variants={{
            closed: { opacity: 0 },
            open: { opacity: 1 }
          }}
          transition={{ duration: 0.1 }}
        />
      </g>
    </svg>
  </a>
);




