import React, { useState } from "react";
import Story from "./Story";
import stories from "./StoriesDatabase";

class Stories extends React.Component {
  constructor() {
    super();
    this.state = {
      story: stories[0].story,
      story_id: stories[0].story_id,
    };
  }
  
  randomQuote() {
    let randomNumber = Math.floor(Math.random() * stories.length) + 1; //exclude story by ID of '0'
    // if (randomNumber >= (stories.length - 1)) randomNumber = stories.length - 1; 
    randomNumber = (randomNumber >= (stories.length - 1)) ? stories.length - 1 : randomNumber;
    return stories[randomNumber];
  }

  shuffleQuotes(array){
    return array.sort(()=>Math.random()-0.5)
  }

  handleClick = () => {
    const generateRandomQuote = this.randomQuote();
    this.setState({
      story: generateRandomQuote.story,
      story_id: generateRandomQuote.story_id
      //story_id: generateRandomQuote.story_id > 0 ? generateRandomQuote.story_id : Math.floor(Math.random() * stories.length) // prevention appearing of story by id=0
    });
    this.shuffleQuotes(stories)
  };

  randomColor() {
    const color = `rgb(
      ${Math.floor(Math.random() * 155)},
      ${Math.floor(Math.random() * 155)},
      ${Math.floor(Math.random() * 155)})`;
    return color;
  }

  render() {
    return (
          <Story
            displayColor={this.randomColor}
            handleClick={this.handleClick}
            {...this.state}
          />    
    );
  }
}

export default Stories;